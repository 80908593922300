const Path = {
  Home: "/",
  Category: "/category",
  DetailCategory: "/category/:id",
  DetailProduct: "/product/:id",
  Login: "/login",
  Signup: "/signup",
  Cart: "/cart",
  Contact: "/contact",
  Checkout: "/checkout",
  Payment: "/payment",
  Admin: "/admin",
  AdminDashBoard: "/admin/dashboard",
  AdminCategory: "/admin/categories",
  AdminCategoryAdd: "/admin/categories/add",
  AdminCategoryEdit: "/admin/categories/:id/edit",
  AdminProduct: "/admin/products",
  AdminProductEdit: "/admin/products/:id/edit",
  AdminProductAdd: "/admin/products/add",
  AdminCart: "/admin/cart",
  AdminLogin: "/admin/login",
  PageNotFound: "/*",
};

export default Path;
